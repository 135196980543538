import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import {RadioGroup, Radio, FormControlLabel, FormLabel} from '@mui/material';
import axios from 'axios';
import { useState } from 'react';

export default function SwedishForm() {

    const [formData, setFormData] = useState({
        name: "",
        rsvp: "yes",
        contact: "",
        allergies: "",
        sent: false
      });
    
    const onSubmit = () => {
        axios.post("https://mattias-sanne-wedding.azure-api.net/register", formData)
        .then((response) => {
        // Handle success (e.g., show a success message)
            console.log("Data sent successfully:", response.data);
            setForm("sent", true);
        })
        .catch((error) => {
        // Handle nameValidationError (e.g., show an nameValidationError message)
        console.nameValidationError("Error sending data:", error);
        });
    }

    const setForm = (key, value) => { 
        setFormData({...formData, [key]: value})
        return true;
    }

  return (
    <Box
      noValidate
      autoComplete="off"
      sx={{width: '100%'}}
    >
        <FormControl variant="standard" sx={{width: "80vw", maxWidth:"400px"}} onSubmit={onSubmit}>
            <Box sx={{ paddingTop:"4vh" }}>
                <TextField 
                    id="input-name" 
                    variant="standard" 
                    label="Namn"
                    fullWidth
                    required
                    value={formData.name}
                    onChange={(e) => setForm("name", e.target.value )}
                    />
            </Box>

            <Box sx={{ paddingTop:"4vh" }}>
                <TextField 
                    id="input-contact" 
                    variant="standard" 
                    label="E-postadress" 
                    fullWidth
                    required
                    value={formData.contact}
                    onChange={(e) => setForm("contact", e.target.value )}
                    />
            </Box>

            <Box alignItems="center" sx={{ paddingTop:"4vh" }}>
                <FormLabel id="rsvp-answer-label">Kommer du att närvara?</FormLabel>
                <RadioGroup
                    aria-label="rsvp-answer-label"
                    name="rsvp-answer"
                    row
                    defaultValue={"yes"}
                    onChange={(e) => setFormData({...formData, rsvp: e.target.value })}
                >
                    <FormControlLabel value="yes" control={<Radio />} label="Jag kommer!" />
                    <FormControlLabel value="no" control={<Radio />} label="Jag kommer inte" />
                </RadioGroup>
            </Box>

            <Box sx={{ paddingTop:"4vh" }}>
                <TextField 
                    id="allergies" 
                    variant="standard" 
                    label="Allergier eller matpreferenser (vegetarian, vegan etc.)"
                    fullWidth
                    multiline
                    value={formData.allergies}
                    onChange={(e) => setFormData({...formData, allergies: e.target.value })}
                    rows={2}
                    />
            </Box>

            <Box sx={{ paddingTop:"4vh" }}>
                <Button type="submit" variant="contained" onClick={onSubmit} disabled={formData.sent}>
                    {formData.sent ? "Tack!" : "Skicka"}
                </Button>
            </Box>
      </FormControl>
    </Box>
  );
}