import header from './header.jpg';
import footer from './footer.jpg';
import collage from './collage.jpeg';
import './App.css';
import Form from './SwedishForm';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';

const theme = createTheme({  
  typography: {
    header1: {
      fontSize: "5vw",
      fontFamily: '"Cochin"',
    },
    subheader: {
      fontSize: 20,
      fontFamily: '"Cochin"',
    },
    names: {
      fontFamily: '"Cochin"',
    },
    body1: {
      fontFamily: '"Cochin"',
    },
    header5: {
      fontFamily: '"Cochin"',
    },
    namesAmpersand: {
      fontSize: 22,
      fontFamily: '"Bad Script"',
      fontWeight: 1000
    }
  },
});

function Swedish() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <img src={header} className="Header" alt="header" />

        <Box xs={{width: "100vw", display: "flex", alignItems: "center"}} md={{width: "80vw"}} sx={{display: "flex", flexDirection: "column", alignItems: "center"}}> 
          <Box sx={{ paddingTop: "10vh", paddingBottom: "16vh" }}>
            <Typography variant="names" > SANNE RETLEV</Typography>
            <Typography variant="namesAmpersand" >&nbsp;&&nbsp;</Typography>
            <Typography variant="names" >MATTIAS RÅDSTRÖM</Typography>
            <br></br>
            <Typography variant="subheader">
              STOCKHOLM 12 OKTOBER 2024
            </Typography>
          </Box>

          <Divider width="80%"></Divider>

          <Box sx={{ paddingTop: "10vh", paddingBottom: "10vh", paddingLeft: "5vh", paddingRight: "5vh", display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Typography variant="header1">
              Program
            </Typography>
            <Typography>
              <a href="/english">
                Information in english.
              </a>
            </Typography>

            <Typography sx={{ paddingTop:"4vh", paddingBottom:"4vh", width:"80%"}}>
              Varmt välkommen att närvara på vår vigsel med efterföljande middag och fest!
              Vi ser fram emot att få fira denna stora dag tillsammans med er i Seglora kyrka och på Winterviken i Stockholm.
            </Typography>

            <Grid container spacing={5} >
              <Grid container item spacing={5}>
                <Grid item xs={12} md={6} >
                  <Typography variant="header5">
                    15.00
                  </Typography>
                  <Typography variant="body1" sx={{ paddingTop: "2vh", paddingBottom: "5vh"}}>
                    Samling i Seglora kyrka på Skansen, Djurgården för vigsel mellan Sanne Retlev och Mattias Rådström.
                  </Typography>


                  <Typography variant="header5">
                    16.30
                  </Typography>         
                  <Typography variant="body1" sx={{ paddingTop: "2vh", paddingBottom: "5vh"}}>
                    Vi bordar M/S Stockholm ström III som avgår från Allmänna gränd på Djurgården och färdas på vattnet genom Stockholm. Mingel och umgänge tillsammans med alla som betyder mest i vårt liv!
                  </Typography> 

                  <Typography variant="header5">
                    18.00
                  </Typography>         
                  <Typography variant="body1" sx={{ paddingTop: "2vh", paddingBottom: "5vh"}}>
                    Nu anländer vi till Alfred Nobels dynamitfabrik Winterviken för mingel, middag och fest! Fördrink, trerätters middag signerad stjärnkocken Markus Aujalay och vickning serveras under kvällen. Bar kommer finnas under festen. Vi ser fram emot att få umgås och fira denna fantastiska kväll med er!  
                  </Typography> 

                  <Typography variant="header5">
                    02.00
                  </Typography>         
                  <Typography variant="body1" sx={{ paddingTop: "2vh", paddingBottom: "5vh"}}>
                    Vi torkar svetten och stretchar vaderna efter några timmars dans. Buss finns bokad för transport till city för de som planerar att festa vidare eller ta sig hem därifrån. En oförglömlig dag har nått sitt slut och vi tar med oss minnen för livet!
                  </Typography>  
                  </Grid>
                <Grid item xs={12} md={6} padding={4}>
                  <img src={collage} className="Collage" alt="Bilder av oss" width={"100%"}/>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Divider width="80%"></Divider>

          <Box sx={{ paddingTop: "10vh", paddingBottom: "10vh", paddingLeft: "5vh", paddingRight: "5vh" }}>
            <Box sx={{paddingBottom: "2vh"}}>
              <Typography variant="header5" >
                Annat
              </Typography>

              <Typography variant="body1" sx={{ paddingTop: "2vh", paddingBottom: "5vh"}}>
              Vi har valt att fira denna dag i vuxet sällskap.<br />
              <br />
              Fri entré på Skansen för alla bröllopsgäster<br />
              <br />
              Begränsad tillgång till parkering vid Winterviken<br />
              <br />
              Önskas lämna festen tidigare än kl 02.00 är det enklast att boka taxi, tunnelbanestation Aspudden finns ca 20 minuters promenad från Winterviken.
              </Typography>
            </Box>

            <Typography variant="header5">
              Klädkod
            </Typography>
            <Typography variant="body1" sx={{ paddingTop: "2vh", paddingBottom: "5vh"}}>
              Mörk kostym            
            </Typography>

            <Typography variant="header5">
              Presenter
            </Typography>
            <Typography variant="body1" sx={{ paddingTop: "2vh", paddingBottom: "5vh"}}>
              Er närvaro är allt vi önskar. Men vill ni ändå uppmärksamma vår dag med en gåva uppskattar vi ett bidrag till vår bröllopsresa.
            </Typography>

            <Typography variant="header5">
              Kontakt
            </Typography>
            <Typography variant="body1" sx={{ paddingTop: "2vh"}}>
              För tal och frågor gällande bröllopsmiddagen; <br />
              Toastmadame Anna Vanvik & Toastmaster Philip Höög<br />
              sannemattias2024@gmail.com <br />
              <br />
              För övriga frågor till brudparet; <br />
              Sanne Retlev | sanne@retlev.se | 073 514 00 96 <br />
              Mattias Rådström | radstrom@hotmail.com | 073 067 91 99      
            </Typography>
          </Box>

          <Divider width="80%"></Divider>

          <Box sx={{ paddingTop: "10vh", paddingBottom: "10vh", width: "80%"}}>
            <Typography variant="header1">
              OSA
            </Typography>
            <Typography variant="body1" sx={{ paddingTop: "2vh", paddingBottom: "5vh"}}>
                Senast 1 juli 2024
            </Typography>
            <Form>
            </Form>
          </Box>
        </Box>
        <img src={footer} className="Footer" alt="footer" />
      </div>
    </ThemeProvider>
  );
}

export default Swedish;
