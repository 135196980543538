import header from './header.jpg';
import footer from './footer.jpg';
import collage from './collage.jpeg';
import './App.css';
import Form from './EnglishForm';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';

const theme = createTheme({  
  typography: {
    header1: {
      fontSize: "5vw",
      fontFamily: '"Cochin"',
    },
    subheader: {
      fontSize: 20,
      fontFamily: '"Cochin"',
    },
    names: {
      fontFamily: '"Cochin"',
    },
    body1: {
      fontFamily: '"Cochin"',
    },
    header5: {
      fontFamily: '"Cochin"',
    },
    namesAmpersand: {
      fontSize: 22,
      fontFamily: '"Bad Script"',
      fontWeight: 1000
    }
  },
});

function English() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <img src={header} className="Header" alt="header" />

        <Box xs={{width: "100vw", display: "flex", alignItems: "center"}} md={{width: "80vw"}} sx={{display: "flex", flexDirection: "column", alignItems: "center"}}> 
          <Box sx={{ paddingTop: "10vh", paddingBottom: "16vh" }}>
            <Typography variant="names" > SANNE RETLEV</Typography>
            <Typography variant="namesAmpersand" >&nbsp;&&nbsp;</Typography>
            <Typography variant="names" >MATTIAS RÅDSTRÖM</Typography>
            <br></br>
            <Typography variant="subheader">
              STOCKHOLM OCTOBER 12th 2024
            </Typography>
          </Box>

          <Divider width="80%"></Divider>

          <Box sx={{ paddingTop: "10vh", paddingBottom: "10vh", paddingLeft: "5vh", paddingRight: "5vh", display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Typography variant="header1">
              Program
            </Typography>
            <Typography>
              <a href="/">
                Information på svenska
              </a>
            </Typography>

            <Typography sx={{ paddingTop:"4vh", paddingBottom:"4vh", width:"80%"}}>
                A warm welcome to celebrate our wedding together with us at Seglora church and Winterviken in Stockholm!
            </Typography>

            <Grid container spacing={5} >
              <Grid container item spacing={5}>
                <Grid item xs={12} md={6} >
                  <Typography variant="header5">
                    3.00 PM
                  </Typography>
                  <Typography variant="body1" sx={{ paddingTop: "2vh", paddingBottom: "5vh"}}>
                      We gather at Seglora church at Skansen, Djurgården for the wedding between Sanne Retlev and Mattias Rådström.
                  </Typography>

                  <Typography variant="header5">
                    4.30 PM
                  </Typography>         
                  <Typography variant="body1" sx={{ paddingTop: "2vh", paddingBottom: "5vh"}}>
                    We board the M/S Stockholm ström III departing from Allmänna gränd, Djurgården and ride through Stockholm's waters. 
                  </Typography> 

                  <Typography variant="header5">
                    6.00 PM
                  </Typography>         
                  <Typography variant="body1" sx={{ paddingTop: "2vh", paddingBottom: "5vh"}}>
                    We arrive at Alfred Nobel's old dynamite factory Winterviken for dinner and party! A three-course menu set by the star chef Marcus Aujalay will be served during the evening. A bar will be available during the party. We look forward to spending time together and celebrating this fantastic evening with you!
                  </Typography> 

                  <Typography variant="header5">
                    2.00 AM
                  </Typography>         
                  <Typography variant="body1" sx={{ paddingTop: "2vh", paddingBottom: "5vh"}}>
                    We wipe off the sweat and stretch our calves after a few hours of dancing. A bus is booked for transportation to the city for those who plan to continue the party or get home from there. An unforgettable day has come to an end!
                  </Typography>  
                  </Grid>
                <Grid item xs={12} md={6} padding={4}>
                  <img src={collage} className="Collage" alt="Pictures of us" width={"100%"}/>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Divider width="80%"></Divider>

          <Box sx={{ paddingTop: "10vh", paddingBottom: "10vh", paddingLeft: "5vh", paddingRight: "5vh" }}>
            <Box sx={{paddingBottom: "2vh"}}>
              <Typography variant="header5" >
                Other
              </Typography>

              <Typography variant="body1" sx={{ paddingTop: "2vh", paddingBottom: "5vh"}}>
              Free entrance to Skansen for all wedding guests <br />
              <br />
              Limited parking availability at Winterviken <br />
              <br />
              If you wish to leave the party earlier than 2:00 am, it is best to book a taxi, the Aspudden subway station is about a 20-minute walk from Winterviken.
              </Typography>
            </Box>

            <Typography variant="header5">
              Dresscode
            </Typography>
            <Typography variant="body1" sx={{ paddingTop: "2vh", paddingBottom: "5vh"}}>
              Formal           
            </Typography>

            <Typography variant="header5">
              Gifts
            </Typography>
            <Typography variant="body1" sx={{ paddingTop: "2vh", paddingBottom: "5vh"}}>
                Your presence is all we wish for. But if you still want to celebrate our day with a gift, we appreciate a contribution towards our honeymoon.
            </Typography>

            <Typography variant="header5">
              Contact
            </Typography>
            <Typography variant="body1" sx={{ paddingTop: "2vh"}}>
              For speeches and questions regarding the dinner:  <br />
              Toastmadame Anna Vanvik<br />
              Toastmaster Philip Höög<br /> 
              sannemattias2024@gmail.com<br />
              <br />
              For all other questions to the bride and groom:  <br />
              Sanne Retlev | sanne@retlev.se | +46 73 514 00 96 <br />
              Mattias Rådström | radstrom@hotmail.com | +46 73 067 91 99      
            </Typography>
          </Box>

          <Divider width="80%"></Divider>

          <Box sx={{ paddingTop: "10vh", paddingBottom: "10vh", width: "80%"}}>
            <Typography variant="header1">
              RSVP
            </Typography>
            <Typography variant="body1" sx={{ paddingTop: "2vh", paddingBottom: "5vh"}}>
                No later than July 1st 2024. 
            </Typography>
            <Form>
            </Form>
          </Box>
        </Box>
        <img src={footer} className="Footer" alt="footer" />
      </div>
    </ThemeProvider>
  );
}

export default English;
